<template>
<div class="FQA">
    <div class="FQA-item" v-for="(item, index) in 5" :key="index" @click="active = index">
      <div class="FQA-item__head d-flex align-center justify-space-between">
        <span>What is NTF Box ?</span>
        <v-icon color="#EB6930">mdi-chevron-down</v-icon>
      </div>
      <v-scroll-y-transition>
        <div class="FQA-item__content" v-show="active === index">
          This is a paragraph with more information about something important. This something has many uses and is made of 100% recycled material.
          This is a paragraph with more information about something important. This something has many uses and is made of 100% recycled material.This is a paragraph with more information about something important.
          This something has many uses and is made of 100% recycled material.
        </div>
      </v-scroll-y-transition>
    </div>
</div>
</template>

<script>
export default {
	name: 'FAQ',
	data () {
		return {
			active: 0,
		}
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.FQA {
		&-item {
			border-radius: 20px;
		}
		&-item__head {
			padding: 35px 0;
		}
		padding: 70px;
	}
}
// mobile
@media (max-width: 960px) {
	.FQA {
		&-item {
			border-radius: 10px;
		}
		&-item__head {
			padding: 15px 0;
		}
		padding: 15px;
	}
}
.FQA {
  &-item {
    &__content {
      padding-bottom: 35px;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: #7E8C98;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__head {
      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        color: #E8E8E8;
      }
      cursor: pointer;
    }
    background: #3C434A;
    padding: 0 30px;
    margin-bottom: 20px;
  }
}
</style>
